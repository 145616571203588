@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  background-color:#0F0F0F;
}

.button-container {
  /* clip-path: polygon(8% 0, 92% 0, 100% 20%, 100% 75%, 92% 100%, 8% 100%, 0 75%, 0% 20%); */
  clip-path: polygon(8% 0, 92% 0, 100% 29%, 100% 71%, 92% 100%, 8% 100%, 0 71%, 0 29%);
}

.triangle-container {
  clip-path: polygon(0 0, 0% 100%, 99% 0);
}

.triangle-container1 {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

:root {
  --sz: 1.5vmin;
  --on: #a3d902;
  --of: #fff0;
  --gr: #fb2727;

  --tr: all 0.5s ease 0s;
  --lg: var(--of);
}

* {
  transition: var(--tr);
}


.toggle {
  position: relative;
  width: calc(var(--sz) * 4);
  height: calc(var(--sz) * 2);
  display: flex;
  align-items: center;
  justify-content: center;
}


label[for=btn] {
  position: absolute;
  width: calc(var(--sz) * 4);
  height: calc(var(--sz) * 2);
  background: linear-gradient(0deg, #a3d902, #0d1217);
  border-radius: var(--sz);

  box-shadow: 0 0 calc(var(--sz) / 50) calc(var(--sz) / 50) rgba(246, 246, 246, 0.4), 0 -4px calc(var(--sz) / 10) calc(var(--sz) / 500) #0b0b10, 0 0px calc(var(--sz) / 10) calc(var(--sz) / 50) #b9e1ff88, 0 -4px calc(var(--sz) / 5) calc(var(--sz) / 50) #15182fcc;

}

.thumb {
  position: absolute;
  width: calc(calc(var(--sz) * 2) - calc(var(--sz) / 8));
  height: calc(calc(var(--sz) * 2) - calc(var(--sz) / 8));
  top: calc(calc(var(--sz) / 10) + calc(var(--sz) / -20));
  left: calc(calc(var(--sz) / 10) + calc(var(--sz) / -30));
  background:
    radial-gradient(circle at 48% 50%, #fff0 calc(var(--sz) / 1.05), var(--lg) calc(var(--sz) / 0.99)),
    radial-gradient(circle at 40% 50%, #fff0 calc(var(--sz) / 0.95), var(--lg) calc(var(--sz) / 0.75)),
    linear-gradient(180deg, #283349, #17212f);
  border-radius: var(--sz);
  box-shadow:
    calc(var(--sz) / -50) calc(var(--sz) / 50) calc(var(--sz) / 30) 0 #fff2 inset,
    0 0 calc(var(--sz) / 10) calc(var(--sz) / 50) #000c,
    0 calc(var(--sz) / 3) calc(var(--sz) / 3) 0 #000d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  overflow: hidden;
}

#btn:checked+label .thumb {
  --lg: var(--on);
  transition: var(--tr);
  left: calc(calc(100% - calc(calc(var(--sz) * 2) - calc(var(--sz) / 3))) - calc(calc(var(--sz) / 10) + calc(var(--sz) / 5.75)));

}

.thumb:before {
  content: "";
  position: absolute;
  width: calc(var(--sz) / 0.5);
  height: calc(var(--sz) / 0.5);
  background: radial-gradient(circle at 45% 50%, #fff0 calc(var(--sz) / 1), var(--lg) calc(var(--sz) / 0.95));
  filter: blur(1px);
}

.light {
  position: absolute;
  width: calc(var(--sz) * 1);
  height: calc(var(--sz) / 1.75);
  right: calc(var(--sz) * -2.75);
  display: flex;
  justify-content: center;
  z-index: 1;

  background: #a3d902;

  position: relative;
  width: calc(var(--sz) / 1.75);
  height: calc(var(--sz) / 1.75);
  border-radius: var(--sz);
  box-shadow:
    0 0px calc(var(--sz) / 50) calc(var(--sz) / 50) #0008,
    0 -4px calc(var(--sz) / 10) calc(var(--sz) / 500) #000,
    0 2px calc(var(--sz) / 10) calc(var(--sz) / 500) #fff8,
    0 0px calc(var(--sz) / 20) calc(var(--sz) / 25) #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--tr);
}

.light:before {
  content: "";
  transition: var(--tr);
  width: calc(100% - calc(var(--sz) / 15));
  height: calc(100% - calc(var(--sz) / 15));
  position: absolute;
  background: radial-gradient(circle at 50% 32%, #fff5 0 calc(var(--sz) / 20), #000 calc(var(--sz) / 3) calc(var(--sz) / 3));
  border-radius: var(--sz);
  box-shadow:
    0 0 calc(var(--sz) / 3) 0 #003ef520,
    0 0 calc(var(--sz) / 3) calc(var(--sz) / 20) #003ef520 inset;
}

#btn:checked+label+.light:before {
  --lg: var(--on);
  transition: var(--tr);
  box-shadow:
    0 0 calc(var(--sz) / 2.5) 0 var(--lg),
    0 0 calc(var(--sz) / 3) calc(var(--sz) / 20) var(--lg) inset,
    0 calc(var(--sz) / -20) calc(var(--sz) / 10) calc(var(--sz) / 10) #a3d902 inset;
  background: radial-gradient(circle at 50% 32%, #a3d902 0 calc(var(--sz) / 20), var(--lg) calc(var(--sz) / 3) calc(var(--sz) / 3));
}


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #ffff;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #ffff;
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    #ffff;
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    #ffff;
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:    #ffff;
}

::placeholder { /* Most modern browsers support this now. */
 color:    #ffff
}

/* this is loader css in verify component */

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  scale: .3;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.loader2 {
  width: 60px;
  display: flex;
  align-items: flex-start;
  aspect-ratio: 1;
}
.loader2:before,
.loader2:after {
  content: "";
  flex: 1;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px,#00ff33 90deg,#1ead0b00 0);
  background: var(--g), var(--g), var(--g);
  filter: drop-shadow(30px 30px 0 #18c208);
  animation: l20 1s infinite;
}
.loader2:after {
  transform: scaleX(-1);
}
@keyframes l20 {
   0%   {background-position:0     0, 10px 10px, 20px 20px}
   33%  {background-position:10px  10px}
   66%  {background-position:0    20px,10px 10px,20px 0   }
   100% {background-position:0     0, 10px 10px, 20px 20px}
}

.glow-text {
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
               0 0 10px rgba(255, 255, 255, 0.5),
               0 0 20px rgba(6, 215, 48, 0.7),
               0 0 40px rgba(5, 209, 63, 0.7),
               0 0 80px rgba(1, 221, 60, 0.7);
}

/* profile page loader status */
.loader_profile {
  width: 40px;
  aspect-ratio: 1;
  color: #6cf033;
  position: relative;
  background: radial-gradient(10px,currentColor 94%,#0000);
}
.loader_profile:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background:
    radial-gradient(9px at bottom right,#0000 94%,currentColor) top    left,
    radial-gradient(9px at bottom left ,#0000 94%,currentColor) top    right,
    radial-gradient(9px at top    right,#0000 94%,currentColor) bottom left,
    radial-gradient(9px at top    left ,#0000 94%,currentColor) bottom right;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  animation: l18 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l18 {
   33%  {inset:-10px;transform: rotate(0deg)}
   66%  {inset:-10px;transform: rotate(90deg)}
   100% {inset:0    ;transform: rotate(90deg)}
}


/* HTML: <div class="loader"></div> */
.loader_login {
  width: 60px;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px,#a3d902 90deg,#0000 0);
  background: var(--g), var(--g), var(--g);
  background-size: 50% 50%;
  animation: l19 1.5s infinite;
}
@keyframes l19 {
   0%   {background-position:0     0, 10px 10px, 20px 20px}
   33%  {background-position:10px  10px}
   66%  {background-position:0    20px,10px 10px,20px 0   }
   100% {background-position:0     0, 10px 10px, 20px 20px}
}

.loader_login_speed{
    width: 40px;
    height: 40px;
    --c:no-repeat linear-gradient(#a3d902 0 0);
    background: var(--c),var(--c),var(--c),var(--c);
    background-size: 21px 21px;
    animation: l5 1s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l5 {
   0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
   33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
   66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
   100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}

/*  loader for upload  */
/* HTML: <div class="loader"></div> */ 
 /* HTML: <div class="loader"></div> */
.loader_upload {
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#7FF296 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#7FF296);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}